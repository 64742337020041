import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import tw from "twin.macro";
import styled from "@emotion/styled";
import Confetti from "react-dom-confetti";

/** @jsx jsx */
import { jsx } from "@emotion/core";

const config = {
  angle: 90,
  spread: 150,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const locationNums = {
  Bentonville: '479-553-7237',
  Fayetteville: '479-301-2256',
  "Siloam Springs": '479-238-8621'
}

function RegistrationComplete() {
  const [active, setActive] = useState(false);

  const history = useHistory();
  const { location: { state: { skip, location } = {} } = {} } = history;

  return (
    <div
      css={[
        tw`text-center max-w-md md:mx-auto mt-16 border-solid border-2 py-8 px-4 md:px-8 border-black`,
        {
          borderRadius: 20,
        },
      ]}
    >
      {skip ? (
        <React.Fragment>
          <h1
            css={tw`text-4xl text-center leading-10 font-semibold text-gray-800 mb-4 mt-16`}
          >
            You've almost completed your profile!
          </h1>
          <div css={tw`flex items-center text-center justify-center`}>
            <div>
              <Confetti
                ref={() => {
                  setActive(true);
                }}
                active={active}
                config={config}
              />
            </div>
          </div>
          <p css={tw`mb-8`}>
            Congratulations, Your profile is almost complete. If you perfer you can come back
            later and fill out your volunteer preferences at a later time.
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h1
            css={tw`text-4xl text-center leading-10 font-semibold text-gray-800 mb-4`}
          >
            You've completed your profile!
          </h1>
          <div css={tw`flex items-center text-center justify-center`}>
            <div>
              <Confetti
                ref={() => {
                  setActive(true);
                }}
                active={active}
                config={config}
              />
            </div>
          </div>
          <p css={tw`mb-16`}>
            Congratulations, your profile is now complete. 
          </p>
        </React.Fragment>
      )}

      <Link
        to="/dashboard"
        css={[
          tw`w-full md:w-auto py-3 text-center rounded-lg font-semibold text-lg mb-8 text-white px-6 ml-auto`,
          {
            backgroundColor: "#333",
            boxShadow: "0px 16px 40px rgba(0, 0, 0, 0.2)",
          },
        ]}
      >
        Continue
      </Link>
      <div css={tw`mt-24 text-sm`}>{`Need help now? Call us at ${locationNums[location] ? locationNums[location] : locationNums["Fayetteville"]}`}</div>
    </div>
  );
}

export default RegistrationComplete;
