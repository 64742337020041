import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import tw from "twin.macro";
import hero from "./hero.jpg";
import boxHearts from "./icon-box-hearts.svg";
import handMoney from "./icon-hand-money.svg";
import community from "./icon-community.svg";
import landing from "./landing.png";
import hug from "./icon-hug.svg";
import { ReactComponent as IconCheck } from "./icon-check.svg";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { SimpleMenu } from "./MenuBar";
import Footer from "./Footer";
import { SubmitButton } from "./Form";

const benefits = [
  {
    icon: community,
    text: "Give back to your community",
  },
  {
    icon: handMoney,
    text: "Earn store credit, or",
  },
  {
    icon: boxHearts,
    text: "Donate to our nonprofit partners",
  },
  {
    icon: hug,
    text: "Make friends in the process",
  },
];

const locationNumbers = [
  ["Bentonville", '479-553-7237'],
  ["Fayetteville", '479-301-2256'],
  ["Siloam Springs",  '479-238-8621'],
];

function SignIn() {
  const { loginWithRedirect } = useAuth0();

  return (
    <React.Fragment>
      <div>
        <SimpleMenu />
      </div>
      <main css={tw`pt-20 relative`}>
        <div css={tw`bg-blb-pink-200 w-full pt-24 pb-20 px-8`}>
          <div css={tw`max-w-6xl mx-auto md:flex`}>
            <header css={tw`md:w-1/2`}>
              <h1 css={tw`text-4xl md:text-6xl mb-8 font-bold md:font-semibold`}>
                Volunteer With Us!
              </h1>
              <p css={tw`text-lg md:text-2xl mb-8`}>
                BLB provides hope and resources for women facing situations of
                vulnerability locally and globally. With YOUR help we can
                continue to provide the support so many women need today!
              </p>
              <div>
                <SubmitButton
                  css={tw`bg-gmb-300 w-auto`}
                  onClick={() =>
                    loginWithRedirect({
                      screen_hint: "signup",
                    })
                  }
                >
                  Register Today
                </SubmitButton>
              </div>
            </header>
            <div css={tw`md:w-1/2 md:pl-16`}>
              <div>
                <img src={landing} alt="" css={tw`w-full`} />
              </div>
            </div>
          </div>
        </div>
        <section css={tw`py-20 px-6`}>
          <div css={tw`max-w-6xl mx-auto`}>
            <div css={tw`md:flex`}>
              <div css={tw`w-full md:w-1/2 mb-4 md:mb-0`}>
                <img
                  src={hero}
                  alt="vounteers smiling"
                  css={{
                    "@media(min-width: 767px)": {
                      position: "relative",
                      top: -120,
                      borderRadius: 12
                    },
                  }}
                />
              </div>
              <div css={tw`md:w-1/2 md:pl-16`}>
                <div>
                  <div css={tw`text-3xl md:text-4xl`}>
                    Join us. Get rewarded!
                  </div>
                  <ul>
                    {benefits.map(({ icon, text }, idx) => (
                      <li key={idx} css={tw`flex items-center mt-6`}>
                        <div css={tw`mr-4`}>
                          <img src={icon} alt="" />
                        </div>
                        <div css={tw`text-lg md:text-2xl`}>{text}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div
              css={tw`text-lg md:text-2xl md:w-1/2 bg-blb-gray-300 p-4 rounded-lg mt-4`}
            >
              <div css={tw`mb-4`}>
                We'll be in touch soon to provide information and get you
                started! Questions in the meantime?
              </div>
              <ul>
                {locationNumbers.map(([location, phone], idx) => (
                  <li key={idx}>
                    <span css={tw`font-semibold`}>{location}: </span>
                    <span>{phone}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export function SignUpButton() {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      type="button"
      css={tw`bg-blb-pink-300 py-3 rounded-lg font-bold w-full mb-4`}
      onClick={() =>
        loginWithRedirect({
          screen_hint: "signup",
        })
      }
    >
      Create new account
    </button>
  );
}

export function LoginButton() {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      type="button"
      css={tw`bg-blb-pink-300 py-3 rounded-lg font-bold w-full mb-4`}
      onClick={() => loginWithRedirect()}
    >
      Login
    </button>
  );
}

export default SignIn;
